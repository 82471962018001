import {Component, Vue} from 'vue-property-decorator';
import {Getter, Mutation} from 'vuex-class';

@Component({name: 'VideoCall'})
export default class VideoCall extends Vue {
    @Getter protected videoMuted!: boolean;
    @Getter protected voiceMuted!: boolean;

    @Mutation protected setVideoMuted;
    @Mutation protected setVoiceMuted;
}
